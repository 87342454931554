<template>
  <component
    v-bind="layoutInfo.specialProps(record)"
    :is="layoutInfo.component"
  >
    <slot :content="record"/>
  </component>
</template>

<script>
export default {
  name: 'OneLevel',
  props: {
    layoutInfo: {
      type: Object,
      default: () => ({})
    },
    record: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
